import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ContactUsButtonComponent } from './contact-us-button.component';

@NgModule({
  declarations: [ContactUsButtonComponent],
  imports: [CommonModule, MatButtonModule, RouterModule],
  exports: [ContactUsButtonComponent],
})
export class ContactUsButtonModule {}
